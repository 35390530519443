import React from 'react';

import { PromoType, getPromoItems } from '../../../sharedModules/getPromoItems';
import type { Deal } from '../../types/Deal';
import { Consumer } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { Promos } from '../GenericComponents/Promos/loadable';

import styles from './styles/promotion-deal.css';

interface PromotionDealProps {
  deal: Deal;
}

const PromotionDeal: React.FC<PromotionDealProps> = ({ deal }) => (
  <Consumer>
    {(value): JSX.Element => {
      const {
        translate,
        buttonType,
        genericSharedComponents: { Button, DisplayPrice },
      } = value;
      const className = styles[buttonType] ? buttonType : 'container';
      const promoItems = getPromoItems(deal, PromoType.ALLOWED, false);

      return (
        <div className={styles[className]}>
          <div className={styles.main}>
            <DisplayPrice
              {...{
                deal,
                label: translate('promotionDealPricePrefix'),
                className: 'promotion',
              }}
            />
            {promoItems.length > 0 && <Promos items={promoItems} className="promotion" />}
          </div>
          <Button
            {...{
              deal,
              className: 'promotion-button',
            }}
          />
        </div>
      );
    }}
  </Consumer>
);

export default PromotionDeal;
