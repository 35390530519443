import React from 'react';

import getProsCons from '../../modules/getProsCons';
import type { Deal } from '../../types/Deal';
import { Consumer } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import styles from './styles/promotion-pros.css';

interface PromotionProsProps {
  deal: Deal;
}

const PromotionPros: React.FC<PromotionProsProps> = ({ deal }) => (
  <Consumer>
    {(value): JSX.Element | null => {
      const { translate, data } = value;
      const pros = getProsCons({
        data,
        modelId: deal?.model_id,
        type: 'pros',
      });

      if (pros && pros.length > 0) {
        return (
          <div className={styles.container}>
            <div className={styles.heading}>{translate('promotionProsHeading')}</div>
            <ul className={styles.list}>
              {pros.map((pro) => (
                <li className={styles.item} key={pro}>
                  {pro}
                </li>
              ))}
            </ul>
          </div>
        );
      }
      return null;
    }}
  </Consumer>
);

export default PromotionPros;
