import type { APIData } from '../types/APIData';
import type { Deal } from '../types/Deal';

interface GetProsConsOptions {
  data: APIData;
  modelId: Deal['model_id'];
  type: string;
}

export default (options: GetProsConsOptions): string[] => {
  const { data, modelId, type } = options;
  if (data.model_info && data.model_info[modelId] && data.model_info[modelId][type]) {
    return data.model_info[modelId][type];
  }
  return [];
};
