import React from 'react';

import type { Deal } from '../../types/Deal';
import { useHawkWidgetContext } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import PromotionDeal from './PromotionDeal';
import PromotionPros from './PromotionPros';
import styles from './styles/promotion-item.css';

interface PromotionItemProps {
  deal: Deal;
}

const PromotionItem: React.FC<PromotionItemProps> = ({ deal }) => {
  const {
    genericSharedComponents: { DealImage, DisplayName },
  } = useHawkWidgetContext();

  return (
    <div className={styles.container}>
      <DisplayName deal={deal} className={styles['display-name']} />
      <div className={styles.innerContainer}>
        <DealImage deal={deal} width={250} height={250} className="promotion" />
        <div className={styles['pros-deal-container']}>
          <PromotionPros deal={deal} />
          <PromotionDeal deal={deal} />
        </div>
      </div>
    </div>
  );
};

export default PromotionItem;
